[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    background: no-repeat center center;
    position: relative;
}

.icon-logo {
    background-image: url('~assets/icons/png/720/logo.png');
}

.icon-more {
    background-image: url('~assets/icons/png/720/more.png');
    @include setProperty(width, 150px);
    @include setProperty(height, 150px);
}

.icon-favourite {
    background-image: url('~assets/icons/png/720/favourite.png');
    @include setProperty(width, 45px);
    @include setProperty(height, 40px);
}

.icon-notify {
    background-image: url('~assets/icons/png/720/notify.png');
    @include setProperty(width, 48px);
    @include setProperty(height, 48px);
}

.icon-record {
    background-image: url('~assets/icons/png/720/record.png');
    @include setProperty(width, 48px);
    @include setProperty(height, 48px);
}

.icon-favourite-pink {
    background-image: url('~assets/icons/png/720/favourite-pink.png');
    @include setProperty(width, 45px);
    @include setProperty(height, 40px);
}

.icon-notify-pink {
    background-image: url('~assets/icons/png/720/notify-pink.png');
    @include setProperty(width, 41px);
    @include setProperty(height, 40px);
}

.icon-record-pink {
    background-image: url('~assets/icons/png/720/record-pink.png');
    @include setProperty(width, 40px);
    @include setProperty(height, 40px);
}

.icon-record-state {
    background-image: url('~assets/icons/png/720/record-state.png');
    @include setProperty(width, 39px);
    @include setProperty(height, 39px);
}

.icon-record-white-pink {
    background-image: url('~assets/icons/png/720/record-white-pink.png');
    @include setProperty(width, 16px);
    @include setProperty(height, 16px);
}

.icon-star-half {
    background-image: url('~assets/icons/png/720/star-half.png');
    @include setProperty(width, 63px);
    @include setProperty(height, 60px);
}

.icon-star {
    background-image: url('~assets/icons/png/720/star.png');
    @include setProperty(width, 63px);
    @include setProperty(height, 60px);
}

.icon-star-small {
    background-image: url('~assets/icons/png/720/star-small.png');
    @include setProperty(width, 42px);
    @include setProperty(height, 40px);
}

.icon-star-white {
    background-image: url('~assets/icons/png/720/star-white.png');
    @include setProperty(width, 63px);
    @include setProperty(height, 60px);
}

.icon-arrow-down {
    background-image: url('~assets/icons/png/720/arrow-down.png');
    @include setProperty(width, 44px);
    @include setProperty(height, 45px);
}

.icon-arrow-up {
    background-image: url('~assets/icons/png/720/arrow-up.png');
    @include setProperty(width, 44px);
    @include setProperty(height, 45px);
}

.icon-backspace {
    background-image: url('~assets/icons/png/720/backspace.png');
    @include setProperty(width, 134px);
    @include setProperty(height, 60px);
}

.icon-done-circle {
    background-image: url('~assets/icons/png/720/done-circle.png');
    @include setProperty(width, 179px);
    @include setProperty(height, 179px);
}

.icon-done-circle-small {
    background-image: url('~assets/icons/png/720/done-circle-small.png');
    @include setProperty(width, 60px);
    @include setProperty(height, 60px);
}


.icon-search {
    background-image: url('~assets/icons/png/720/search.png');
    @include setProperty(width, 90px);
    @include setProperty(height, 90px);
}

.icon-search-tv {
    background-image: url('~assets/icons/png/720/search-tv.png');
    @include setProperty(width, 90px);
    @include setProperty(height, 90px);
}

.icon-search-big {
    background-image: url('~assets/icons/png/720/search-big.png');
    @include setProperty(width, 150px);
    @include setProperty(height, 150px);
}

.icon-play-button {
    background-image: url('~assets/icons/png/720/play-button.png');
    @include setProperty(width, 62px);
    @include setProperty(height, 62px);
}

.icon-play-button-big {
    background-image: url('~assets/icons/png/720/play-button-big.png');
    @include setProperty(width, 100px);
    @include setProperty(height, 100px);
}

.icon-audio {
    background-image: url('~assets/icons/png/720/audio.png');
    @include setProperty(width, 67px);
    @include setProperty(height, 61px);
}

.icon-subtitles {
    background-image: url('~assets/icons/png/720/subtitles.png');
    @include setProperty(width, 60px);
    @include setProperty(height, 60px);
}

.icon-audio-dolby-digital {
    background-image: url('~assets/icons/png/720/audio-dolby-digital2.png');
    @include setProperty(width, 74px);
    @include setProperty(height, 60px);
}

.icon-audio-surround {
    background-image: url('~assets/icons/png/720/audio-surround.png');
    @include setProperty(width, 85px);
    @include setProperty(height, 60px);
}

.icon-locked {
    background-image: url('~assets/icons/png/720/locked.png');
    @include setProperty(width, 30px);
    @include setProperty(height, 39px);
}

.icon-beta {
    background-image: url('~assets/icons/png/720/beta.png');
    @include setProperty(width, 200px);
    @include setProperty(height, 200px);
}

.icon-wrong-pass {
    background-image: url('~assets/icons/png/720/wrong-pass.png');
    @include setProperty(width, 41px);
    @include setProperty(height, 40px);
}

.icon-play-new {
    background-image: url('~assets/icons/png/720/play-new.png');
    @include setProperty(width, 40px);
    @include setProperty(height, 42px);
}

.icon-pause-new {
    background-image: url('~assets/icons/png/720/pause-new.png');
    @include setProperty(width, 30px);
    @include setProperty(height, 40px);
}

.icon-pause-big {
    background-image: url('~assets/icons/png/720/pause-big.png');
    @include setProperty(width, 120px);
    @include setProperty(height, 160px);
}

.icon-start-over-new {
    background-image: url('~assets/icons/png/720/start-over-new.png');
    @include setProperty(width, 58px);
    @include setProperty(height, 42px);
}

.icon-play-live {
    background-image: url('~assets/icons/png/720/play-live.png');
    @include setProperty(width, 58px);
    @include setProperty(height, 42px);
}

.icon-play-live-button {
    background-image: url('~assets/icons/png/720/play-live-button.png');
    @include setProperty(width, 62px);
    @include setProperty(height, 62px);
}

.icon-start-over-button {
    background-image: url('~assets/icons/png/720/start-over-button.png');
    @include setProperty(width, 62px);
    @include setProperty(height, 62px);
}

.icon-hide {
    background-image: url('~assets/icons/png/720/hide.png');
    @include setProperty(width, 60px);
    @include setProperty(height, 56px);
}

.icon-show {
    background-image: url('~assets/icons/png/720/show.png');
    @include setProperty(width, 66px);
    @include setProperty(height, 36px);
}

.icon-teleport {
    background-image: url('~assets/icons/png/720/teleport.png');
    @include setProperty(width, 60px);
    @include setProperty(height, 55px);
}

.icon-teletext {
    background-image: url('~assets/icons/png/720/teletext.png');
    @include setProperty(width, 60px);
    @include setProperty(height, 47px);
}

.icon-pen {
    background-image: url('~assets/icons/png/720/pen.png');
    @include setProperty(width, 40px);
    @include setProperty(height, 40px);
}

.icon-trash {
    background-image: url('~assets/icons/png/720/trash.png');
    @include setProperty(width, 41px);
    @include setProperty(height, 43px);
}

.icon-remove-circle {
    background-image: url('~assets/icons/png/720/remove-circle.png');
    @include setProperty(width, 48px);
    @include setProperty(height, 48px);
}

.icon-remove-circle-big {
    background-image: url('~assets/icons/png/720/remove-circle-big.png');
    @include setProperty(width, 150px);
    @include setProperty(height, 150px);
}

.icon-check-circle-fill {
    background-image: url('~assets/icons/png/720/check-circle-fill.png');
    @include setProperty(width, 48px);
    @include setProperty(height, 48px);
}

.icon-buy {
    background-image: url('~assets/icons/png/720/buy.png');
    @include setProperty(width, 47px);
    @include setProperty(height, 39px);
}

.icon-detail {
    background-image: url('~assets/icons/png/720/detail.png');
    @include setProperty(width, 49px);
    @include setProperty(height, 49px);
}

.icon-device-tv {
    background-image: url('~assets/icons/png/720/device-tv.png');
    @include setProperty(width, 60px);
    @include setProperty(height, 52px);
}

.icon-device-web {
    background-image: url('~assets/icons/png/720/device-web.png');
    @include setProperty(width, 72px);
    @include setProperty(height, 48px);
}

.icon-device-mobile {
    background-image: url('~assets/icons/png/720/device-mobile.png');
    @include setProperty(width, 36px);
    @include setProperty(height, 54px);
}

.icon-vol-up {
    background-image: url('~assets/icons/png/720/vol-up.png');
    @include setProperty(width, 166px);
    @include setProperty(height, 162px);
}

.icon-mute {
    background-image: url('~assets/icons/png/720/mute.png');
    @include setProperty(width, 162px);
    @include setProperty(height, 162px);
}

.icon-skip-ad {
    background-image: url('~assets/icons/png/720/skip-ad.png');
    @include setProperty(width, 66px);
    @include setProperty(height, 32px);
}
